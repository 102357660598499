<style>
  div {
    margin-bottom: var(--size-m);
    background-image: linear-gradient(
      45deg,
      #dedede 5%,
      #ffffff 5%,
      #ffffff 50%,
      #dedede 50%,
      #dedede 55%,
      #ffffff 55%,
      #ffffff 100%
    );
    background-size: 10px 10px;
  }
</style>

<div>
  <slot />
</div>
