<script>
  import MeetingPreview from "src/components/meeting/StyleGuide.svelte";
  import PollDetails from "src/pages/Polls/details/StyleGuide.svelte";
  import MeetingDetails from "src/pages/Meetings/details/StyleGuide.svelte";
  import Join from "src/pages/member/join/StyleGuide.svelte";
  import Welcome from "src/pages/member/welcome/StyleGuide.svelte";
  import Notification from "src/components/notification/StyleGuide.svelte";
  import Header from "src/components/header/StyleGuide.svelte";
  import Banner from "src/components/banner/StyleGuide.svelte";
</script>

<h1>Style guide</h1>

<MeetingPreview />

<PollDetails />

<MeetingDetails />

<Join />

<Welcome />

<Banner />

<Header />

<Notification />
