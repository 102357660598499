<script>
  import Widget from "./Widget.svelte";
  import Item from "src/components/styleguide/Item.svelte";
</script>

<h2>Banner</h2>

<Item>
  <Widget name="SayDAO" />
</Item>
